<template>
    <b-col sm="12">
          <iq-card body-class=" profile-page p-0">
            <template v-slot:body>
              <div class="profile-header p-1">
                <b-row>
                  <b-col cols="8">
                    <div class="cover-container">
                      <b-carousel
                        v-if="hotelImgs && hotelImgs.length !==0"
                        id="carouselExampleIndicators"
                        controls
                        indicators
                      >
                        <b-carousel-slide v-for="(img,index) in hotelImgs" style="height: 300px;object-fit: cover" :key="index"  :img-src="img.image_path"></b-carousel-slide>
                      </b-carousel>
                      <div class="deault-slide pt-5 w-100 text-white bg-primary d-flex flex-column align-items-center justify-content-center" style="height: 300px;" v-else>
                        <i class="ri-image-line" style="font-size: 30px;"></i>
                        <router-link class="text-white px-3 py-1 border hotel-imgs" to="/hotel-imgs">
                          {{ $t('upload-imag') }}
                        </router-link>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="4">
                    <div class="profile-info p-4">
                      <b-row>
                        <b-col md="12" sm="12" class="text-center">
                          <img class="img-fluid border rounded-sm w-50 mx-auto" style="margin: 0px auto;" :src="hotel.image" alt="">
                        </b-col>
                        <b-col md="12" sm="12" class="align-self-center">
                          <div class="user-detail">
                              <div class="profile-detail text-center">
                                <h4>{{hotel.title}}</h4>
                                <p class="m-0"> {{$t('price')}} {{ hotel.start_price }} $ - {{ hotel.end_price }} $ </p>
                              </div>
                            <div class="text-center">
                                <span v-for="star in hotel.stars" :key="star">
                                  <i class="ri-star-fill" style="color: gold"></i>
                                </span>
                              </div>
                              <div class="text-center">
                                <b-badge  variant="primary"><span >
                                  {{ hotel.total_views }} {{$t('views')}}
                                </span></b-badge>
                              </div>
                            </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </template>
          </iq-card>
        </b-col>
</template>
<script>
export default {
  mounted () {
    this.getHotel()
    this.getHotelImg()
  }
}
</script>
<style>
.cover-container img{
  min-height: 300px;
}

</style>
